import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'
import { ComponentPropsWithoutRef, ElementType } from 'react'

import {
  Accordion,
  BodyTextBlock,
  DatoGatsbyImage,
  List,
} from '@/features/common/'
import {
  FacultySubsection,
  bodyGrid,
  bodyHeading,
} from '@/features/page-sections'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?:
    | Queries.BodySectionFragment
    | Queries.BodySectionWithImageFragment
    | null
  bgColor?: 'WHITE' | 'GRAY' | 'RED'
  headingLevel?: 2 | 3
  disableLightboxLinks?: boolean
  layout?: 'PAGE' | 'ARTICLE'
  imagePosition?: 'LEFT' | 'RIGHT'
}

export const BodySection = ({
  data,
  bgColor,
  headingLevel = 2,
  disableLightboxLinks,
  layout = 'PAGE',
  imagePosition,
  ...props
}: Props): JSX.Element => {
  const numberedHeading = data?.heading?.match(/^\d+\.\s/)
  const heading = (
    (numberedHeading
      ? data?.heading?.slice(numberedHeading?.[0]?.length)
      : data?.heading) || ''
  ).replace(/\//, '/​')
  const Heading = `h${headingLevel}` as ElementType
  const styles = {
    section: css`
      ${bgColor === 'WHITE' &&
      css`
        background: #fff;
      `}
      ${bgColor === 'GRAY' &&
      css`
        background: ${colors.gray95};
      `}
      ${bgColor === 'RED' &&
      css`
        background: ${colors.red};
        color: #fff;
        --link-color: #fff;
        --link-color-hover: #000;
        --button-hover-bg-color: #fff;
        --button-hover-color: ${colors.red};
      `}
      ${bodyGrid}
      align-items: flex-start;
      ${data?.__typename === 'DatoCmsBodySectionWithImage' &&
      css`
        grid-auto-flow: dense;
        ${imagePosition === 'LEFT' &&
        css`
          grid-template-columns: 5fr 7fr;
        `}
        ${imagePosition === 'RIGHT' &&
        css`
          grid-template-columns: 7fr 5fr;
        `}
        ${mq().ms} {
          grid-template-columns: 1fr;
        }
      `}
    `,
    heading: css`
      ${bodyHeading}
      ${bgColor === 'RED' &&
      css`
        color: #fff;
        border-color: #fff;
      `}
      ${numberedHeading &&
      css`
        display: flex;
        gap: 0.25em;
      `}
      ${layout === 'ARTICLE' &&
      css`
        font-size: var(--fs-48);
      `}
      ${imagePosition === 'LEFT' &&
      css`
        grid-column: -2 / -1;
      `}
      ${imagePosition === 'RIGHT' &&
      css`
        grid-column: 1 / -2;
      `}
      mark {
        background: transparent;
        display: block;
        font-size: var(--fs-48);
        color: ${colors.gray30};
      }
    `,
    blocks: css`
      ${imagePosition === 'LEFT' &&
      css`
        grid-column: -2 / -1;
      `}
      ${imagePosition === 'RIGHT' &&
      css`
        grid-column: 1 / -2;
      `}
    `,
    image: css`
      grid-row: span ${2 + (data?.blocks?.length || 0)};
      ${imagePosition === 'LEFT' &&
      css`
        grid-column: 1 / 2;
      `}
      ${imagePosition === 'RIGHT' &&
      css`
        grid-column: -2 / -1;
      `}
      ${mq().ms} {
        margin-bottom: 2em;
      }
    `,
    body: css`
      max-width: 90ch;
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      {data?.__typename === 'DatoCmsBodySectionWithImage' && (
        <DatoGatsbyImage
          image={data?.image?.gatsbyImageData}
          alt={data?.image?.alt || ''}
          css={styles.image}
        />
      )}
      <Heading css={styles.heading}>
        {numberedHeading && <span>{numberedHeading[0]}</span>}
        <Markdown
        // options={{
        //   overrides: {
        //     p: ({ children }) => children,
        //   },
        // }}
        >
          {heading.replace(/\{/, '<mark>').replace(/\}/, '</mark>')}
        </Markdown>
      </Heading>
      <div css={styles.blocks}>
        {data?.blocks?.map((block, i) => {
          switch (block?.__typename) {
            case 'DatoCmsBodyTextBlock':
              return (
                <BodyTextBlock
                  key={i}
                  data={block}
                  disableLightboxLinks={disableLightboxLinks}
                  css={styles.body}
                />
              )
            case 'DatoCmsAccordion':
              return (
                <Accordion
                  data={block}
                  key={i}
                />
              )
            case 'DatoCmsList':
              return (
                <List
                  data={block}
                  key={i}
                  headingLevel={(headingLevel + 1) as 3 | 4}
                />
              )
            case 'DatoCmsFacultySubsection':
              return (
                <FacultySubsection
                  data={block}
                  key={i}
                  headingLevel={headingLevel + 1}
                />
              )
          }
        })}
      </div>
    </section>
  )
}

export const BodySectionFragment = graphql`
  fragment BodySection on DatoCmsBodySection {
    __typename
    id: originalId
    heading
    blocks {
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
      ... on DatoCmsAccordion {
        ...Accordion
      }
      ... on DatoCmsList {
        ...List
      }
      ... on DatoCmsFacultySubsection {
        ...FacultySubsection
      }
    }
  }
  fragment BodySectionWithImage on DatoCmsBodySectionWithImage {
    __typename
    id: originalId
    heading
    blocks {
      ... on DatoCmsBodyTextBlock {
        ...BodyTextBlock
      }
      ... on DatoCmsAccordion {
        ...Accordion
      }
      ... on DatoCmsList {
        ...List
      }
    }
    image {
      gatsbyImageData(
        width: 1080
        sizes: "(max-width: 720px) 100vw, 50vw"
      )
      alt
    }
  }
`
